/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";

const Bio = () => {
    const data = useStaticQuery(graphql`
        query BioQuery {
            site {
                siteMetadata {
                    author {
                        name
                        summary
                    }
                }
            }
        }
    `);

    // Set these values by editing "siteMetadata" in gatsby-config.js
    const author = data.site.siteMetadata?.author;

    return (
        <div className="bio">
            <h4>:</h4>
            <p>반갑습니다. 프론트엔드 엔지니어 진정수의 블로그입니다.</p>
            <p>다시 보려고 작성한 글들을 모아두었습니다.</p>
            <p>
                제가 궁금하시다면{" "}
                <a href="/about" style={{ fontWeight: 500 }}>
                    소개 페이지
                </a>
                를 들러주세요.
            </p>
        </div>
    );
};

export default Bio;
